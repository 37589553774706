import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public connectedUser$ = this.authenticationService.connectedUser$;

  constructor(private authenticationService: AuthenticationService) {}

  public logout() {
    this.authenticationService.logout();
  }
}
