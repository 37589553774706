<div class="page-wrapper">
    <div class="pb-2">
        <p class="h3">Plage horaire</p>
    </div>
    <div class="section-wrapper mb-5">
    <div class="justify-content-between d-flex align-items-center">
        <p class="h6">Pour étendre les horaires d’ouverture et ou de fermeture cliquez sur le bouton “Ajouter une extension”</p>
        @if(canAddExtension) {
        <button type="button" class="btn btn-primary d-flex align-items-center">
            <icon type="add_circle_outline"/>
            Ajouter une extension
        </button>
        }
    </div>
    </div>
</div>


