<ng-container *ngIf="connectedUser$ | async as user">
  <nav class="navbar navbar-expand-sm flex-column">
    <div class="container-fluid d-flex w-100">
      <div class="w-100">
        <div class="container-fluid d-flex pt-2 pe-0 align-items-center">
          <!-- Application Name -->
          <img  class="icon" src="assets/icons/logo_asphaltor.svg" alt="Logo Asphaltor" id="asphaltor-logo" />
          <div class="ms-5">
            <tabs>
              <tab customCssClass="asph-custom-tab" [route]="'/orders'">Planning des commandes</tab>
              <tab customCssClass="asph-custom-tab" [route]="'/unavailabilities'">Indisponibilités</tab>
              <tab customCssClass="asph-custom-tab" [route]="'/planning'">Planning optimisé</tab>
              @if (selectedSiteName === 'Normandie') {
              <tab customCssClass="asph-custom-tab" [route]="'/extension'">Plage horaire</tab>
              }
            </tabs>
          </div>
          <!-- Navbar content -->
          <ul class="navbar-nav ms-auto d-flex flex-row">
            <li class="nav-item me-4">
              <button class="btn btn-outline-link">
                <span class="material-icons-outlined">notifications</span>
              </button>
            </li>
            <li class="nav-item ms-md-4">
              <div class="dropdown">
                <span class="navbar-text" data-hj-suppress>{{ user.name }}</span>
                <button class="btn btn-outline-link" id="profile" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="material-icons-outlined">account_circle</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profile">
                  <li>
                    <a class="dropdown-item" href="#" (click)="logout()">Logout</a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item d-none d-xl-block">
              <div *ngIf="sites.length > 1; else singleSite" class="dropdown">
                <div class="d-flex">
                  <button
                    aria-expanded="false"
                    class="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    id="site"
                    type="button">
                    {{ selectedSiteName }}
                  </button>
                  <ul aria-labelledby="site" class="dropdown-menu dropdown-menu-end">
                    <li *ngFor="let site of sites">
                      <a
                        (click)="selectSite(site.code, site.name)"
                        class="dropdown-item"
                       >
                        {{ site.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <ng-template #singleSite>
                <div class="d-flex">
                  <span class="btn">{{ selectedSiteName }}</span>
                </div>
              </ng-template>
            </li>

            <li class="nav-item d-none d-xl-block">
              <div class="dropdown">
                <div class="d-flex">
                  <button class="btn dropdown-toggle" type="button" id="localisation2" data-bs-toggle="dropdown" aria-expanded="false">Fr</button>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="localisation2">
                    <li><a class="dropdown-item" href="#">France</a></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- TotalEnergies Logo -->
      <a href="#">
        <img src="assets/images/te_logo.png" alt="Logo TotalEnergies" id="totalenergies-logo" />
      </a>
    </div>
  </nav>
</ng-container>
