import { ErrorHandler, NgModule } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

@NgModule({
  providers: [
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class AppInsightsModule {
  constructor(private readonly appInsightsService: AppInsightsService) {}
}
