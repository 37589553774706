import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const extensionGuard: CanActivateFn = () => {
  const router = inject(Router);
  const selectedSite = localStorage.getItem('selectedSite');

  if (selectedSite === 'GONF') {
    return true; 
  }

  router.navigate(['/']);
  return false;
};
