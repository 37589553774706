import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { activateLogin } from './security/activate-login.guard';
import { ExtensionComponent } from './pages/extension/extension.component';
import { extensionGuard } from './security/extension.guard';

const routes: Routes = [
  {
    path: 'orders',
    loadChildren: () =>
      import('./pages/bol-orders/bol-orders.module').then(
        (m) => m.BolOrdersModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'unavailabilities',
    loadChildren: () =>
      import('./pages/unavaibilities/unavaibilities.module').then(
        (m) => m.UnavaibilitiesModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'planning',
    loadChildren: () =>
      import('./pages/planning/planning.module').then(
        (m) => m.PlanningModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'extension', 
    component: ExtensionComponent,
    canActivate: [MsalGuard, extensionGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [activateLogin],
  },
  {
    path: '**',
    redirectTo: 'orders',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
