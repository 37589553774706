import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { AsyncError, getErrorMessenger, getSuccessMessenger } from 'src/shared/utils/state-handling.utils';
import Hotjar from '@hotjar/browser';
import { environment } from '../shared/infrastructure/environments/environment';
import { ModalElementComponent } from '../shared/components/organisms/modal/modal-element.component';
import { AppInsightsService } from 'src/shared/infrastructure/insights/app-insights.service';
import { ConnectedUser } from 'src/shared/models/security/connected-user.interface';
import { AuthenticationService } from './security/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  errorMessenger$: BehaviorSubject<AsyncError> = new BehaviorSubject<AsyncError>({message: ''});
  successMessenger$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  onLogin$: BehaviorSubject<ConnectedUser | null> = this.authenticationService.onLogin$;
  onLogout$: BehaviorSubject<void> = this.authenticationService.onLogout$;

  @ViewChild('optimInformationConfirmDialog') optimInformationConfirmDialog!: ModalElementComponent;

  errorMessageBody: string = '';

  constructor(private messageService: MessageService, private appInsightsService: AppInsightsService, private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.setMessengers();
    this.initHotjar();
    this.postLogin();
    this.postLogout();
  }

  initHotjar(){
    Hotjar.init(environment.hotJar.appId, environment.hotJar.version, {
      nonce: 'rAnDoM'
    });
  }

  setMessengers(): void {
    this.errorMessenger$ = getErrorMessenger();
    this.successMessenger$ = getSuccessMessenger();

    this.errorMessenger$.subscribe((error:AsyncError) => {
      if(!error.isPopup) {
        this.messageService.add({ key: 'errorAlert', severity: 'error', summary: 'Erreur', detail: error.message });
      }else {
        this.errorMessageBody = error.message;
        this.optimInformationConfirmDialog.open();
      }
    });

    this.successMessenger$.subscribe((success:string) => {
      this.messageService.add({key: 'successAlert', severity:'success', summary:'Succès', detail: success});
    });
  }

  postLogin() {
    this.onLogin$.subscribe((connectedUser: ConnectedUser | null) => {
      if(connectedUser === null)  {
        this.appInsightsService.clearUserId();
        return;
      }
      this.appInsightsService.setUserId(connectedUser.username);
    });
  }

  postLogout() {
    this.onLogout$.subscribe(() => {
      this.appInsightsService.clearUserId();
    });
  }


  ngOnDestroy(): void {
    this.errorMessenger$.complete();
    this.successMessenger$.complete();
    this.onLogin$.complete();
    this.onLogout$.complete();
  }

}
