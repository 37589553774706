import { EnvironmentInterface } from '../@types/environment';

export const environment: EnvironmentInterface = {
  backend_url: process.env['API_BASE_URL'] ?? '',
  authentication: {
    clientId: process.env['AZURE_AD_CLIENT_ID'] ?? '',
    authority: process.env['AZURE_ENTRA_ID_AUTHORITY'] ?? '',
    scopes: [process.env['AZURE_AD_SCOPE_API'] ?? ''],
    redirectUri: '/orders',
  },
  appInsights: {
    instrumentationKey: process.env['AZURE_INSIGHT_KEY'] ?? '',
    autoTrackPageVisitTime: true,
  },
  hotJar: {
    version: 6,
    appId: 4995336
  },
  production: false,
};
