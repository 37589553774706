import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { SharedModule } from 'src/shared/shared.module';

@Component({
  selector: 'app-extension',
  templateUrl: './extension.component.html',
  styleUrl: './extension.component.scss',
  imports: [SharedModule],
  standalone: true
})
export class ExtensionComponent implements OnInit {
  roles: string[] = [];
  canAddExtension = false;

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authenticationService.roles$.subscribe(roles => {
      this.roles = roles;
      this.canAddExtension = roles.some(role => role.includes('EXTENSIONS'));
    });
  }
}
