import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { TimeZoneConverter } from '../infrastructure/resources/time-zone-converter';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private readonly timeZoneConverter: TimeZoneConverter) {}

  transform(startDateString: string, endDateString: string): string {
    const startDate: moment.Moment = moment.tz(
      startDateString,
      this.timeZoneConverter.timeZone,
    );

    if (endDateString) {
      const endDate: moment.Moment = moment.tz(
        endDateString,
        this.timeZoneConverter.timeZone,
      );

      if (startDate.format('DD-MM-YYYY') === endDate.format('DD-MM-YYYY')) {
        return `De ${this.dateToFormatHoursMinutes(startDate)} à ${this.dateToFormatHoursMinutes(endDate)}`;
      } else {
        return `Du ${this.dateToFormatToolTip(startDate)} au ${this.dateToFormatToolTip(endDate)}`;
      }
    } else {
      return `Du ${this.dateToFormatToolTip(startDate)}`;
    }
  }

  dateToFormatHoursMinutes(date: moment.Moment): string {
    return date.format('HH[h]mm');
  }

  dateToFormatToolTip(date: moment.Moment): string {
    return ` ${date.format('DD[/]MM')} à ${this.dateToFormatHoursMinutes(date)}`;
  }
}
