import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SecurityModule } from './security/security.module';
import { SharedModule } from 'src/shared/shared.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  RefineryCodeInterceptor
} from 'src/shared/infrastructure/interceptors/refinery-code-interceptor-interceptor.service';
import { AppInsightsModule } from 'src/shared/infrastructure/insights/app-insights.module';

registerLocaleData(localeFr);

@NgModule({
  bootstrap: [AppComponent, MsalRedirectComponent],
  declarations: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' }, {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: RefineryCodeInterceptor
    },
    MessageService,
    ConfirmationService
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SecurityModule,
    SharedModule,
    ToastModule,
    AppInsightsModule
  ]
})
export class AppModule {
}
